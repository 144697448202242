import React, { useState, useEffect } from "react";
import { Select, Table, Button, Card, Row } from "antd";
import api from "utils/api";
import DatePicker from "components/DatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { parseQueryParams, stringifyQueryParams } from "utils/url";
import { useLocation, useNavigate, Link } from "react-router-dom";
import PageTitle from "components/PageTitle";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const antbtnlg = {
  padding: " 6.4px 15px",
  fontSize: "16px",
  borderRadius: "8px",
  height: "60px",
};
const { Option } = Select;
const url = new URL(window.location.href);
const merchantid = url.searchParams.get("merchantid");

const ResellerTransactionList = () => {
  const [merchantid, setMerchantid] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [records, setRecords] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState([]);
  const [perPage, setPerPage] = useState(
    process.env.REACT_APP_RECORDS_PER_PAGE
  );

  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const titles = [{ path: location.pathname, title: "Reseller Transaction List" }];
  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
  },
 
  // {
  //     title: 'Merchant Email',
  //     key: 'merchant_email',
  //     dataIndex: 'merchant_email',
  // },
  

  {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'subtotal',
  },
  
  {
      title: 'Date',
      key: 'created_at',
      dataIndex: 'created_at',
  },
  ];

  const fetchData = async (isTableLoading, keyword, dateRange) => {
    setIsTableLoading(true);
    // alert(dateRange);
    try {
      let start, end;

      if (dateRange === "today") {
        //  alert(dateRange);
        start = new Date().toISOString().slice(0, 10);
        end = start;
      } else if (dateRange === "yesterday") {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        start = end = yesterday.toISOString().slice(0, 10);
      } else if (dateRange === "thisWeek") {
        const currentDate = new Date();
        const firstDayOfWeek = new Date(
          currentDate.setDate(currentDate.getDate() - currentDate.getDay())
        );
        start = firstDayOfWeek.toISOString().slice(0, 10);
        end = new Date().toISOString().slice(0, 10);
      } else if (dateRange === "last7Days") {
        const currentDate = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        start = sevenDaysAgo.toISOString().slice(0, 10);
        end = new Date().toISOString().slice(0, 10);
      } else if (dateRange === "last30Days") {
        const currentDate = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);
        start = thirtyDaysAgo.toISOString().slice(0, 10);
        end = new Date().toISOString().slice(0, 10);
      } else if (Array.isArray(dateRange) && dateRange.length === 2) {
        start = dateRange[0].toISOString().slice(0, 10);
        end = dateRange[1].toISOString().slice(0, 10);
      } else {
        // Default to fetching all records
        start = "";
        end = "";
      }
      const queryParameters = new URLSearchParams(window.location.search);
      const type = queryParameters.get("merchantid");
      const response = await api.get("Reseller-transaction-list-second", {
        params: {
          page,
          per_page: perPage,
          isTableLoading,
          merchantid: type,
        },
        start: start,
        end: end,
      });

      const data = response.data;
      const merchantData = response.data.data;
      console.warn(merchantData);
      const totalRecords = response.data.total_record;
      setRecords(merchantData);
      setPage(response.data.page);
      console.warn(response.data.page);
      setPerPage(response.data.per_page);
      setTotalCount(totalRecords);
      setIsTableLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(merchantid, startDate, endDate, status, dateRange);
  }, []);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    if (dates === "today") {
      fetchData("", "today");
    } else {
      fetchData("", dates);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPerPage(pageSize);
    setIsTableLoading(false);
  };

  
  const onSearch = (keyword) => {
    let query = parseQueryParams(location.search);
    // alert(query);
    query = {
      ...query,
      page: 1,
      keyword: keyword,
    };

    navigate.push({
      pathname: location.pathname,
      search: stringifyQueryParams(query),
    });

    fetchData(keyword);
  };
  const availableModes = [
    { key: "today", label: "Today" },
    { key: "yesterday", label: "Yesterday" },
    { key: "this_week", label: "This week" },
    { key: "last7days", label: "Last 7 days" },
    { key: "last30days", label: "Last 30 days" },
  ];

  return (
    <div>
      <div className="overviewBorder">
        <Row justify="space-between" align="middle">
          <Card className="small_card">
            <PageTitle titles={titles} />
          </Card>
          <Link to="/transaction">
            <Card className="small_card">
              <Button
                type="primary"
                size="large"
              >
                <span style={{ marginRight: "7px" }}>
                  &larr;
                </span>{" "}
                Back
              </Button>
            </Card>
          </Link>
        </Row>
      </div>
      
      <Table
        className="mt-8"
        loading={isTableLoading}
        dataSource={records}
        columns={columns}
        pagination={{
          total: totalCount,
          showTotal: (total) => `Total ${total} items`,
          // pageSize: perPage,
          // current: page,
          onChange: handlePageChange,
          showLoading: isTableLoading,
        }}
        scroll={{
          x: true,
        }}
      />
    </div>
  );
};
export default ResellerTransactionList;
