import React, { useEffect, useRef,useState } from 'react';
import { useSelector } from 'react-redux';
import { Spin, Table, Button, Switch, Modal, Row, Col,Card } from 'antd';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import DatePicker from 'components/DatePicker';
import dayjs from 'dayjs';
import TableBar from 'components/TableBar';
import { parseQueryParams, stringifyQueryParams } from 'utils/url';
// request
import { getUsers } from 'requests/user';
import {
    addgetPayoutsBeneficiaryAccounts,
} from 'requests/payouts';

const Transaction = () => {
    const [isTableLoading, setIsTableLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(process.env.REACT_APP_RECORDS_PER_PAGE);
    const [totalCount, setTotalCount] = React.useState(0);
    const [records, setRecords] = React.useState([]);
    const [currentId, setCurrentId] = React.useState(null);
    const [users, setUsers] = React.useState([]);
    const { RangePicker } = DatePicker;
    const config = useSelector((state) => state.config);

    const searchRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const titles = [{ path: location.pathname, title: 'Beneficiaries' }];

    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
        },

        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (text, record) => (
                <div>
                    {record.merchant_data && record.merchant_data.full_name ? (
                        <div>{record.merchant_data.full_name}</div>
                    ) : (
                        <div>No full name available</div>
                    )}
                </div>
            ),
        },
       
        {
            title: 'Merchant Email',
            key: 'merchant_email',
            dataIndex: 'merchant_email',
            render: (text, record) => (
                <div>
                    {record.merchant_data && record.merchant_data.email ? (
                        <div>{record.merchant_data.email}</div>
                    ) : (
                        <div>No email available</div>
                    )}
                    
                    
                </div>
            ),
        },


        {
            title: 'Wallet Amount',
            key: 'payout_amount_settlement',
            dataIndex: 'payout_amount_settlement',
            render: (text, record) => (

                <div>
                {record.merchant_data && record.merchant_data.payout_amount_settlement ? (
                    <div>{record.merchant_data.payout_amount_settlement}</div>
                ) : (
                    <div>No available</div>
                )}
                
                
            </div>

            ),
        },
        
        // {
        //     title: 'Amount',
        //     key: 'amount',
        //     dataIndex: 'amount',
        // },
        
        // {
        //     title: 'Date',
        //     key: 'created_at',
        //     dataIndex: 'created_at',
        // },

        {
            title: "Action",
            render: (text, record) => {
              return (
                <Link to={`/transaction-pending?merchantid=${record.userId}`}>
                  <Button className="color1">View</Button>
                </Link>
              );
            },
          },
        
        
    ];

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await getUsers({ is_paginate: 0 });
                setUsers([...data.records]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        const query = parseQueryParams(location);
        getRecords(query);
    }, [location]);

    const getRecords = async (query) => {
        try {
            setIsTableLoading(true);
            const response = await addgetPayoutsBeneficiaryAccounts(query);
            console.warn(response.data);

            setRecords(response.data);
            setPage(response.page);
            setPerPage(response.per_page);
            setTotalCount(response.total_records);
        } catch (err) {
            console.log(err);
        } finally {
            setIsTableLoading(false);
        }
    };

    const onSearch = (keyword) => {
        let query = parseQueryParams(location);
        query = {
            ...query,
            page: 1,
            keyword: keyword,
        };

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query),
        });
    };

    const onChangeTable = (pagination) => {
        console.log(pagination);

        let query = parseQueryParams(location);
        query = {
            ...query,
            page: pagination.current,
            per_page: pagination.pageSize,
        };

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query),
        });
    };

    const [dates, setDates] = useState([dayjs(), dayjs()]);
    const [mode, setMode] = useState('today');
    const [data, setData] = useState({})
    const onSetDatesByDatePicker = (dates) => {
        setMode('custom');
        setDates(dates);
    };
    return (
        <div>
            <Row gutter={[16, 8]} align={'middle'} justify={'space-between'} className='bgred'>
                <Col xs={24} md={12} lg={8} xl={7}>
                    <Card className="wallet_box">
                        <TableBar
                            onSearch={onSearch}
                            showFilter={false}
                            placeholderInput="Search..."
                            inputRef={searchRef}
                        />
                    </Card>
                </Col>
                {/* <Col xs={24} md={12} lg={8} xl={7}>
                <Card className="wallet_box">
                        <RangePicker value={dates} onCalendarChange={(newDates) => onSetDatesByDatePicker(newDates)} />
                    </Card>
                </Col> */}
            </Row>
            <Spin spinning={isTableLoading}>
                <Table
                    style={{ marginTop: '10px' }}
                    dataSource={records}
                    columns={columns}
                    onChange={onChangeTable}
                    rowKey={'id'}
                    pagination={{
                        pageSize: perPage,
                        total: totalCount,
                        current: page,
                    }}
                />
            </Spin>
        </div>
    );
};

export default Transaction;
