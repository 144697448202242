import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, Outlet, useLocation } from 'react-router-dom';
// layouts
import AppLayout from 'layout/app';
import CheckoutLayout from 'layout/checkout';
// actions
import { getAuthUserAction as getAuthUser } from 'redux/actions/auth';
import { getConfigAction as getConfig } from 'redux/actions/config';
// components
import Loading from 'components/Loading';
import Signin from 'routes/auth/Signin';
import Signup from 'routes/auth/Signup';
import Profile from 'routes/profile/profile';
import ForgotPassword from 'routes/auth/ForgotPassword';
import ResetPassword from 'routes/auth/ResetPassword';
import UserVerification from 'routes/auth/UserVerification';
import UserSetToken from 'routes/auth/UserSetToken';
import Error404 from 'routes/error/Error404';
import Error401 from 'routes/error/Error401';
import SmsList from './sms/SmsList';
import Transaction from './transaction/Transaction';
import Report from './transaction/Report';
import ResellerTransactionList from './transaction/ResellerTransactionList';
import OverviewPayout from './dashboard';
import ChangePassword from './profile/changepassword';
const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const authUser = useSelector((state) => state.auth.authUser);

    if (authUser) {
        if (!authUser.status) return <Navigate to="/otp-verification" replace />;

        return children;
    }
    localStorage.setItem('originPath', location.pathname);

    return <Navigate to="/signin" state={{ from: location }} replace />;
};

const AppRoutes = () => {
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        async function getData() {
            try {
                await dispatch(getConfig());
                await dispatch(getAuthUser());
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, []);

    if (loading) return <Loading />;

    return (
        <Routes>
            {/* Error pages */}
            <Route path="*" element={<Error404 />} />
            <Route exact path="/401" element={<Error401 />} />
            <Route exact path="/404" element={<Error404 />} />
            {/* Registration pages */}
            <Route exact path="/signin" element={<Signin />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/setToken" element={<UserSetToken />} />
            <Route exact path="/otp-verification" element={<UserVerification />} />
            <Route
                element={
                    <ProtectedRoute>
                        <CheckoutLayout>
                            <Outlet />
                        </CheckoutLayout>
                    </ProtectedRoute>
                }
            ></Route>
            {/* Dashboard */}
            <Route
                element={
                    <ProtectedRoute>
                        <AppLayout>
                            <Outlet />
                        </AppLayout>
                    </ProtectedRoute>
                }
            >
                <Route exact path="/" element={<Navigate to="/dashboard" />} />
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/change-password" element={<ChangePassword />} />
                <Route path="/sms" element={<SmsList />} />
                <Route exact path="/dashboard" element={<OverviewPayout />} />
                <Route path="/transaction" element={<Transaction />} />
                <Route path="/transaction-pending" element={<Report />} />
                <Route path="/reseller-transaction-list" element={<ResellerTransactionList />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
