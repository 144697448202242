import api from 'utils/api';

/**
 * User API
 */

export const getUsers = (query) => {
	return new Promise((resolve, reject) => {
		return api
			.get('/partner/users', { params: query })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updateUser = (id, data) => {
	return new Promise((resolve, reject) => {
		return api
			.put(`/partner/users/${id}`, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const onboardUsers = (data) => {
	return new Promise((resolve, reject) => {
		return api
			.post('/partner/users/onboarding', data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
