import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import { Dropdown, Typography, Layout,Button, Modal, Row, Col, Space } from 'antd';
// icons

import { UserOutlined, IdcardOutlined } from '@ant-design/icons';
// requests
import { logoutAction as logout } from 'redux/actions/auth';
import api from 'utils/api';
import wallet from 'assets/images/Wallet 1.png';

const { Header } = Layout;
const { confirm } = Modal;

const AppHeader = () => {
    const { Title } = Typography;
    const [TotalAmts, TotalAmt_show] = useState('');
    const [TotaltransactionAmts, TotalAmttransaction_show] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
   
    useEffect(() => {
        fetchUserAmount();
    }, []);

    const fetchUserAmount = async () => {
        try {
            const response = await api.get('/Reseller-header-amount');
            console.warn(response);
            const TotalAmt = response.data.data.reseller_amount;
            TotalAmt_show(TotalAmt);
           
        } catch (error) {
            console.error('Error fetching user amount:', error);
        }
    };
    const handleMenuClick = (e) => {
        console.log('click', e);
    };

    const handleMenuClickprofile = (e) => {
        if (e.key === '2') {
            navigate('/profile');
        } else {
            console.log('click', e);
        }
    };

    const handleMenuClickchangepassword = (e) => {
        if (e.key === '1') {
            navigate('/change-password');
        } else {
            console.log('click', e);
        }
    };


    const items = [
        // {   
        //     label: 'MID',
        //     className:'headerDrop',
        //     key: '1',
        //     icon: <IdcardOutlined />,
        // },
        
        {   
            label: 'Change Password',
            className:'headerDrop',
            key: '1',
            onClick: handleMenuClickchangepassword,
            
        },
    ];


    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    return (
        <Header className="app-header">
            <Row justify={'space-between'} align={'middle'}>
                <Col xs={12} md={12}>
                    <Title level={2} className='mb-0'>Dashboard</Title>
                </Col>
                <Col xs={12} md={12}>
                    <div className='logout_right'>
                    {/* <div className='wallet_amount'>
                     <span> {TotalAmts}</span>
                     <img src={wallet} alt='wallet' className='wallet'/>
                    </div> */}
                        
                        <Col xs={12} md={12}>
                            <div className='logout_right'>
                            <Dropdown menu={menuProps}>
                                <Button className="user">
                                    <Space>
                                        <UserOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                            </div>
                        </Col>
                    
                    </div>
                </Col>
            </Row>
        </Header>
    );
};

export default AppHeader;
