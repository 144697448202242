import React, { useState, useEffect } from 'react';
import { Layout, Menu, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutAction as logout } from 'redux/actions/auth';
// images
import logo from 'assets/images/logo.png';
import logoIcon from 'assets/images/favicon.png';
import api from 'utils/api';
// icons
import { ArrowRight, Logout as LogoutIcon } from 'react-iconly';
import { HiMenuAlt2 } from 'react-icons/hi';
import payoutoverviewicon from '../../assets/images/payout/payoutoverviewicon.png';
import fundtransfer from '../../assets/images/payout/fundtransfer.png';
import overviewPayoutWhite from '../../assets/images/payoutWhite/overviewPayoutWhite.png';
import fundtransferWhite from '../../assets/images/payoutWhite/fundtransferWhite.png';
import payoutusersWhite from '../../assets/images/payoutWhite/user_white.png';
import payoutusers from '../../assets/images/payout/payoutusers.png';

const url = new URL(window.location.href);
const { Sider } = Layout;
const { confirm } = Modal;

const menu = [
    {
        title: 'Dashboard',
        icon: [
            <img
                src={payoutoverviewicon}
                alt="Overview"
                width={24}
                height={24}
                className="normalWhite ant-menu-item-icon"
            />,
            <img
                src={overviewPayoutWhite}
                alt="Overview"
                width={24}
                height={24}
                className="activeWhite ant-menu-item-icon"
            />,
        ],
        key: 'payoutOverview',
        path: '/dashboard',
    },

    {
        title: 'Transaction',
        icon: [
            <img src={fundtransfer} alt="Overview" width={24} height={24} className="normalWhite ant-menu-item-icon" />,
            <img
                src={fundtransferWhite}
                alt="Overview"
                width={24}
                height={24}
                className="activeWhite ant-menu-item-icon"
            />,
        ],
        key: 'PayoutAddBeneficiary',
        path: '/transaction',
    },

    {
        title: 'Profile',
        key: 'Profile',
        icon: [
            <img src={payoutusers} alt="User" width={24} height={24} className="normalWhite ant-menu-item-icon" />,
            <img src={payoutusersWhite} alt="User" width={24} height={24} className="activeWhite ant-menu-item-icon" />,
        ],
        path: '/profile',
    },

    {
        title: 'Logout',
        key: 'Logout',
        icon: <LogoutIcon set="light" width={24} height={24} />,
    },
];
// }

const AppSidebar = (props) => {
    const { isCollapsed, toggleCollapse } = props;
    // const [isCollapsed, setIsCollapsed] = useState(true);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [bankList, setBankList] = useState([]);
    const [permissionbank, setPermissionBank] = useState(null);
    const [permissionpayout, setPermissionPayout] = useState(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const dispatch = useDispatch();

    const onLogout = () => {
        confirm({
            title: 'Are you sure you want to logout?',
            onOk: () => {
                dispatch(logout());
                navigate('/signin');
            },
        });
    };

    const handleMenuClick = (item) => {
        if (item.key === 'Logout') {
            onLogout();
        } else {
            console.log('click', item);
        }
    };
    const menuProps = {
        menu,
        onClick: handleMenuClick,
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        fetchBankList();
        fetchBankAdmin();
    }, []);
    const fetchBankList = async (keyword) => {
        try {
            const response = await api.get('/get-user');
            const data = response.data;
            const permission = data.data.payout_permission;
            const payoutTransferBank = data.data.payout_transfer_bank;
            const payoutTransferPayout = data.data.payout_transfer_payout;
            setBankList(data.data.payout_permission);
            setPermissionBank(payoutTransferBank);
            setPermissionPayout(payoutTransferPayout);
        } catch (error) {
            console.error('Error fetching bank list:', error);
        }
    };

    const [widthdrawshow, setwidthdrawshow] = useState([]);
    const [transfertopayout, settransfertopayout] = useState(null);
    const [transfertobank, settransfertobank] = useState(null);
    const [transaction, settransaction] = useState();
    const [alltransaction, setalltransaction] = useState();
    const [pendingalltransaction, setpendingtrnasction] = useState();
    const [settlement, setsettlement] = useState();
    const [paymentlink, setpaymentlink] = useState();
    const [setting, setsetting] = useState();
    const [all_permission, setall_permission] = useState();
    const [payout_all_permission, setpayout_all_permission] = useState();
    const [addBeneficary, setaddBeneficary] = useState();
    const [fund_transfer, setfund_transfer] = useState();
    const [payoutparmisionreports, setpayoutparmisionreports] = useState();

    const fetchBankAdmin = async (keyword) => {
        try {
            const response = await api.get('/partner-Permission-Data', {});
            const data = response.data;
            var obj = response.data.PermissionData;
            var objpsyout = response.data.PermissionDataPayout;
            setwidthdrawshow(obj.widthdraw);
            settransfertopayout(obj.transfer_to_payout);
            settransfertobank(obj.transfer_to_bank);
            settransaction(obj.transaction);
            setalltransaction(obj.all_transaction);
            setpendingtrnasction(obj.pending_trnasction);
            setsettlement(obj.settlement);
            setpaymentlink(obj.payment_link);
            setsetting(obj.setting);
            setall_permission(obj.all_permission);
            setaddBeneficary(objpsyout.add_benificially);
            setfund_transfer(objpsyout.fund_transfer);
            setpayoutparmisionreports(objpsyout.reports);
            setpayout_all_permission(objpsyout.payout_all_permission);
            console.warn(objpsyout.payout_all_permission);
        } catch (error) {
            console.error('Error fetching bank list:', error);
        }
    };
    useEffect(() => {
        let childIndex = -1;
        const selectedMenuItem = menu.find((item) => {
            if (item.children) {
                let tmp = item.children.findIndex((child) => location.pathname.startsWith(child.path));

                if (tmp >= 0) {
                    childIndex = tmp;
                    return true;
                }
                return false;
            } else {
                return location.pathname.startsWith(item.path);
            }
        });

        if (selectedMenuItem) {
            if (selectedMenuItem.children) {
                setSelectedKeys([selectedMenuItem.key, selectedMenuItem.children[childIndex].key]);
                if (isCollapsed) setOpenKeys([]);
                else setOpenKeys([selectedMenuItem.key]);
            } else {
                setSelectedKeys([selectedMenuItem.key]);
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        if (isCollapsed) setOpenKeys([]);
    }, [isCollapsed]);

    const onToggleSubMenu = (key) => {
        if (openKeys.includes(key)) {
            setOpenKeys([]);
        } else {
            setOpenKeys([key]);
        }
    };

    const onClick = (e) => {
        console.log('click ', e);
    };

    return (
        <Sider className={`app-sidebar`} theme="light" collapsed={isCollapsed}>
            {isCollapsed ? (
                <img src={logoIcon} className="logo-collapsed mt-8 ml-8" />
            ) : (
                <img src={logo} className="logo ml-24 mr-24 mt-8" />
            )}

            <Menu
                mode="inline"
                className={`sidebar-menu`}
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                onClick={handleMenuClick}
            >
                <Menu.Item
                    key={'collapse'}
                    icon={
                        isCollapsed ? (
                            <ArrowRight set="light" width={24} height={24} />
                        ) : (
                            <HiMenuAlt2 set="light" width={24} height={24} className="humburger" />
                        )
                    }
                    onClick={toggleCollapse}
                    className="menu-item--collapse toggle"
                >
                    {isCollapsed ? 'Expand Menu' : 'Collapse Menu'}
                </Menu.Item>

                {menu.map((item) => {
                    if (item.key === 'all_permission' && all_permission === 'false') {
                        return null;
                    }

                    if (item.key === 'payout_all_permission' && payout_all_permission === 'false') {
                        return null;
                    }
                    if (item.children && item.children.length) {
                        return (
                            <Menu.SubMenu
                                key={item.key}
                                icon={item.icon}
                                title={item.title}
                                onTitleClick={() => onToggleSubMenu(item.key)}
                            >
                                {item.children.map((child) => {
                                    if (child.key === 'withdrawal/payout' && transfertopayout != 'true') {
                                        return null;
                                    }

                                    if (child.key === 'withdrawal/bank' && transfertobank != 'true') {
                                        return null;
                                    }

                                    if (child.key === 'transaction' && transaction != 'true') {
                                        return null;
                                    }

                                    if (child.key === 'withdrawal' && widthdrawshow != 'true') {
                                        return null;
                                    }

                                    if (child.key === 'paymentlink' && paymentlink != 'true') {
                                        return null;
                                    }

                                    if (child.key === 'settings' && setting != 'true') {
                                        return null;
                                    }

                                    if (child.key === 'PayoutAddBeneficiary' && addBeneficary != 'true') {
                                        return null;
                                    }

                                    if (child.key === 'payoutFund' && fund_transfer != 'true') {
                                        return null;
                                    }

                                    if (child.key === 'payoutReport' && payoutparmisionreports != 'true') {
                                        return null;
                                    }

                                    return (
                                        <Menu.Item
                                            key={child.key}
                                            icon={child.icon}
                                            onClick={() => navigate(child.path)}
                                        >
                                            {child.title}
                                        </Menu.Item>
                                    );
                                })}
                            </Menu.SubMenu>
                        );
                    }
                    return (
                        <Menu.Item key={item.key} icon={item.icon} onClick={() => navigate(item.path)}>
                            {item.title}
                        </Menu.Item>
                    );
                })}
            </Menu>
        </Sider>
    );
};

export default AppSidebar;
